export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'ultimate-feature',
    label: 'Feature',
  },
  {
    path: 'pricing',
    label: 'Pricing',
  },
  {
    path: 'faq',
    label: 'Faq',
  },
];
