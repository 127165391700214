import React from 'react';
import styled from 'styled-components';
import { Box, Image, jsx } from 'theme-ui';

import MockingbirdLogo from '../assets/images/mockingbird-logo.svg';
import { Link } from '../components/link.component';

const StyledLogo = styled(MockingbirdLogo)`
  height: 24px;
`;

export default function Logo() {
  return (
    <Link
      path="/"
      sx={{
        variant: 'links.logo',
      }}
      label=""
    >

      <StyledLogo className="logo-img" />
    </Link>
  );
}
